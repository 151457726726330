.footer {
  background: linear-gradient(135deg, #b20a4b, rgba(156, 0, 76, 0.8)),
    url("../../assets/images/backFooter.png");
  background-size: cover, 42% auto;
  background-position: center, right center;
  background-repeat: no-repeat;
  color: #fff;
  padding: 100px 0px;
  text-align: center;
}

.footer .container-lg,
.footer .container-xxl {
  max-width: 1500px;
}


.footer ul{
    list-style: none;
    text-align: left;
    padding-left: 0px;
}

.footer ul li{
  margin-bottom: 10px;
}

.footer h3{
    text-align: left;
    margin-bottom: 40px;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
  margin-left: 5%;
}

.footer-left {
  flex: 1;
  min-width: 250px;
  text-align: left;
  margin-right: 10px;
}

.footer-subscribe {
  width: 300px;
  /* margin-left: 5%; */
  /* margin-top: -80px; */
}

.footer-subscribe label {
  font-weight: bold;
  display: block;
  margin: 10px 0;
  text-align: left;
}

.footer-subscribe .email-label {
  margin-bottom: -10px;
  margin-left: 25px;
}

.subscribe-box {
  display: flex;
  align-items: center;
  background: #b20a4b;
  border: 3px solid #c81555;
  border-radius: 25px;
  margin-bottom: 15px;
}

.subscribe-box input {
  border: none;
  outline: none;
  padding: 8px;
  padding-left: 25px;
  flex: 1;
  font-size: 14px;
  border-radius: 25px;
  background-color: #b20a4b;
}

.subscribe-box button {
  background: #b20a4b;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
}

.subscribe-box input::placeholder {
  color: white;
}

.footer-links {
  display: flex;
  flex: 2;
  justify-content: space-around;
  min-width: 250px;
  text-align: left;
}

.footer-column h3 {
  font-size: 22px;
  margin-bottom: 20px;
  margin-right: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 5px;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s;
}

.footer-column ul li:hover {
  color: #ffd700;
}

.under-links {
  margin-top: 25px;
  margin-right: 100px;
  direction: rtl;
  font-size: 20px;
}

.under-links h3 {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.social-icons {
  width: 200px;
  display: flex;
  gap: 18px;
  direction: ltr;
}

.custom-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-button img {
  width: 30px;
  height: auto;
}

.footer-bottom {
  background: #fff;
  padding: 10px 0;
  font-weight: bold;
  color: #000;
  text-align: center;
}

.footer-bottom-content{
  display: flex;
  justify-content: space-between;
  align-items:center;
  margin-top:30px;
  flex-wrap:wrap;

}

@media screen and (max-width: 992px) {
  .under-links {
    /* margin-left: 22%; */
    /* direction: ltr; */
    /* margin-top: 10px; */
    margin-right: 0px;
    /* margin-bottom: 30px; */
  }

  .footer-subscribe {
    margin-top: 0px;
    width:100%;
  }

  .footer h3{
    text-align:center;
    margin-bottom:20px;
    /* height: 35rem; */
  }

  .footer ul li{
    text-align:center
  }

  .footer p{
    margin-bottom:30px;
    text-align:center !important;
  }

  .footer .col-sm-12{
    margin-bottom:30px;
  }
  .footer .col-sm-12:last-child{
    margin-bottom:0px;
  }

  .footer-left {
    text-align: center !important;
  }

  .footer-bottom-content{
    justify-content:center;
    gap:20px
  }
  

}

@media screen and (max-width: 596px) {
  .footer-subscribe {
    margin-top: 0px;
  }

  .footer {
    /* height: 50rem; */
  }
}
