/* General Styles */
.products-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-top: -10%;
}

@media screen and (max-width: 1380px) {
  .products-container {
    margin-top: -140px;
  }
}

.product-item,
.product-container,
.gulf-drink-container {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-item img,
.product-container img,
.gulf-drink-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.product-content,
.gc-cola-content,
.gulf-drink-content,
.juice-item-content {
  width: 90%;
  padding: 20px;
  border-radius: 10px;
  color: white;
  margin-top: 20px;
  text-align: center;
}

.product-title,
.gulf-drink-title {
  font-size: 50px;
  color: white;
}

.product-subtitle {
  font-size: 30px;
  color: #ffc600;
  margin-bottom: 20px;
  text-align: center;
}

.olive-oil-subtitle {
  font-size: 30px;
  color: #ffc600;
  margin-bottom: 20px;
  text-align: start;
  font-weight: 300;
}

.gulf-drink-subtitle {
  font-size: 30px;
  color: #8ae900;
  margin-bottom: 45px;
}

.product-description,
.gulf-drink-description {
  font-size: 16px;
  line-height: 1.5;
  color: white;
}

.juices_desc ul {
  list-style-position: inside;
  text-align: left;
  padding: 0;
  color: white;
  font-size: 18px;
}

/* GC Cola Specific Styles */
.product-item-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
}

.product-text {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.line4 {
  font-weight: 700;
  font-size: 80px;
  color: white;
}

.line5 {
  color: #ff1010;
  font-size: 32px;
}

.line6 {
  color: white;
  max-width: 80%;
  text-align: start;
}

.gulf-drink-container {
  height: auto;
}

.title {
  font-size: 15px;
  font-weight: 600;
}

.product-note {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}

.gulf-drink-icon {
  height: 27px !important;
  width: 55px !important;
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gc-cola-icon {
  height: 27px !important;
  width: 55px !important;
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.juice-icon {
  height: 27px !important;
  width: 55px !important;
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sun-flower-icon{
  height: 27px !important;
  width: 55px !important;
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.olive-oil-icon {
  height: 27px !important;
  width: 55px !important;
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Desktop Styles */

@media (min-width: 769px) {
  .product-container,
  .gulf-drink-container {
    flex-direction: row;
    align-items: flex-start;
  }

  .product-content {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    width: 40%;
    margin-top: 0;
    text-align: left;
  }

  .gc-cola-content {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    width: 40%;
    margin-top: 0;
    text-align: left;
  }

  .gulf-drink-content {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translateY(-50%);
    width: 40%;
    margin-top: 0;
    text-align: start;
  }

  .juice-content {
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
    width: 30%;
    margin-top: 0;
    text-align: start;
  }

  .olive-oil-content {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    width: 40%;
    margin-top: 0;
    text-align: left;
    /* padding: 15px; */
  }

  .product-title,
  .gulf-drink-title {
    font-weight: 700;
    font-size: 80px;
    white-space: nowrap;
  }

  .juice-title {
    text-align: start;
    font-weight: 700;
    font-size: 80px;
  }

  .product-subtitle {
    font-size: 40px;
    text-align: start;
  }

  .product-benefits {
    padding-top: 20px;
  }

  .product-benefits ul {
    list-style-type: disc;
    padding: 20px;
  }

  .gulf-drink-subtitle {
    font-size: 40px;
  }

  .juice-subtitle {
    font-size: 40px;
    text-align: start;
    color: #ffc600;
  }

  .product-description {
    font-size: 16px;
    text-align: left;
  }

  .gulf-drink-description {
    font-size: 21px;
    text-align: left;
    margin-top: 30px;
    font-weight: 300;
  }

  .gulf-drink-links {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .gulf-drink-icons {
    width: 200px;
    display: flex;
    gap: 18px;
    justify-content: center;
    margin-bottom: 20px;
  }

  .gc-cola-links {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .gc-cola-icons {
    width: 200px;
    display: flex;
    gap: 18px;
    justify-content: center;
    margin-bottom: 20px;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .down-arrow{
    display: none !important;
  }
  .product-content {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 6%;
    left: 0%;
    transform: none;
    margin-top: 0px;
  }

  .olive-oil-content {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 25px;
    left: 0%;
    transform: none;
    padding: 20px;
  }

  .juice-content {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 25%;
    left: 0%;
    transform: translateY(-50%);
  }

  .gulf-drink-content {
    width: 90%;
    text-align: center;
    position: absolute;
    top: 27%;
    left: 5%;
    transform: translateY(-50%);
  }

  .gc-cola-content {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 22%;
    left: 0%;
    transform: translateY(-50%);
  }

  .product-title {
    font-size: 33px;
    text-align: center;
    margin-bottom: 0px;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
  }

  .gulf-drink-title {
    font-size: 35px;
    text-align: center;
  }

  .juice-title {
    font-size: 35px;
    text-align: center;
  }

  .product-subtitle {
    font-size: 18px;
    text-align: center;
    margin-bottom: 15px;
  }

  .gulf-drink-subtitle {
    font-size: 18px;
    text-align: center;
    margin-bottom: 5px;
  }

  .juice-subtitle {
    font-size: 17px;
    text-align: center;
    color: #ffc600;
    margin-bottom: 30px;
  }

  .product-description {
    font-size: 14.5px;
    text-align: left;
    line-height: 20px;
  }

  .product-benefits {
    margin-top: 0px;
  }

  .product-benefits p {
    margin-bottom: 0px;
    font-size: 14.5px;
    line-height: 18px;
    text-align: left;
  }

  .gulf-drink-description {
    font-size: 15px;
    text-align: left;
  }

  .product-benefits ul {
    list-style-position: inside;
    padding-left: 20px;
    padding-top: 5px;
    width: 100%;
    color: white;
    text-align: left;
  }

  .product-benefits li {
    font-size: 14.5px;
  }

  .juices_desc ul {
    list-style-position: inside;
    padding-left: 20%;
    width: 100%;
    color: white;
  }

  /* .gulf-drink-title{
    line-height: 74px;
  } */

  .line4 {
    font-size: 35px;
  }

  .line5 {
    font-size: 17px;
  }

  .line6 {
    font-size: 15px;
    max-width: 100%;
    text-align: left;
  }

  .line7 {
    font-size: 30px;
  }

  .line8 {
    font-size: 20px;
  }

  .line9 {
    font-size: 30px;
    margin-top: 2%;
  }

  .product-item-content {
    width: 100%;
    padding: 0 20px;
  }

  .gc-cola-icons {
    width: 100%;
    display: flex;
    gap: 18px;
    justify-content: center;
    margin-bottom: 5px;
  }

  .gulf-drink-icons {
    width: 100%;
    display: flex;
    gap: 18px;
    justify-content: center;
    margin-bottom: 20px;
  }

  .olive-oil-subtitle {
    font-size: 17px;
    text-align: left;
    color: #ffc600;
    margin-bottom: 20px;
    font-weight: 300;
    margin-bottom: 10px;
  }
  .olive-oil-content ul {
    padding-top: 5px;
  }
  .olive-oil-content .product-description {
    font-size: 14px !important;
    line-height: 18px;
  }

    .gulf-drink-icon {
      height: 10px !important;
      width: 18px !important;
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    .gc-cola-icon {
      height: 10px !important;
      width: 18px !important;
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    .juice-icon {
      height: 10px !important;
      width: 18px !important;
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    .sun-flower-icon {
      height: 10px !important;
      width: 18px !important;
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    .olive-oil-icon {
      height: 10px !important;
      width: 18px !important;
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
}

/* Animation */
@keyframes scrollText {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

.animation-container {
  background-color: #ad0d49;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 130px;
  justify-content: center;
}
.animation-container .left-to-right,
.animation-container .right-to-left {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 50px;
}
.animation-container .text-box {
  display: flex;
  flex-shrink: 0;
  height: 50px;
  align-items: center;
  padding-top: 100px;
  gap: 50px;
}

.animation-container .text-box .text {
  position: relative;
}
.animation-container .text-box .text:nth-child(even) {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}

.animation-container .text-box .text img {
  height: 200px;
  position: absolute;
  right: 0px;
  transform: translate(48%, -50%);
  top: 50%;
}

.animation-container .text-box .text h1 {
  font-size: 135px;
  text-transform: uppercase;
  font-weight: 800;
}

.animation-container .left-to-right .text-box {
  animation: slide-right 30s linear infinite;
}

.animation-container .right-to-left .text-box {
  animation: slide-left 30s linear infinite;
}

@keyframes slide-right {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@keyframes slide-left {
  from {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@media screen and (max-width: 950px) {
  .animation-container .text-box .text h1 {
    font-size: 95px;
  }
  .animation-container .text-box .text img {
    height: 150px;
  }

  .animation-container {
    gap: 60px;
  }

  .animation-container .text-box {
    gap: 0px;
  }

}
.down-arrow{
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateY(-50%);
  margin-top: 23px;
  height: 30px;
  width: auto !important;
}



@media screen and (min-width:800px) and (max-width:1200px){
  .product-title, .gulf-drink-title
    .product-title, .gulf-drink-title,.juice-title,.line4 {
        font-size: 30px;
        margin-bottom:0px
    }
    .product-subtitle,.juice-subtitle,.line5{
        font-size:25px;
        margin-bottom:10px;
    }
    .line6{
      font-size:14px;
    }
    .juices_desc ul{
      font-size:14px;
    }
    .product-content{
      width:50%;
      left:20px;
    }
    .product-description{
      font-size: 14px;
      margin-bottom: -6px;
    }

    .product-benefits{
      font-size: 14px;
    }

    .product-benefits ul{
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .olive-oil-content{
      width:47%;
      right:20px;
    }
    .olive-oil-box{
      display:flex;
      justify-content:flex-start;
    }
    .olive-oil-subtitle{
      font-size:25px;
      margin-bottom:10px;
    }

    /* .olive-oil-image{
      height:500px !important;
      width:auto !important;
    } */
    .gulf-drink-subtitle{
      font-size:28px; 
      margin-bottom: 30px;
    }
    .gulf-drink-description{
      font-size:14px
    }
    .gulf-drink-content{
      right:2%;
      width:48%;
      top:50%;
    }
    .gulf-drink-link{
      font-size: 18px;
    }

    .animation-container{
      gap:30px;
    }
    .animation-container .text-box .text h1{
      font-size: 70px;
    }

    .animation-container .text-box .text img {
      height:122px
    }

    .down-arrow{
      /* display: none !important; */
      height: 15px !important;
    }
}
@media screen and (min-width:1101px) and (max-width:1200px){
  .product-title, .gulf-drink-title
    .product-title, .gulf-drink-title,.juice-title,.line4 {
        font-size: 42px;
        margin-bottom:0px
    }
    .product-subtitle,.juice-subtitle,.line5{
        font-size:28px;
        margin-bottom:15px;
    }
  
    .olive-oil-subtitle{
      font-size:25px;
      margin-bottom:15px;
    }

    /* .olive-oil-image{
      height:500px !important;
      width:auto !important;
    } */
    .gulf-drink-subtitle{
      font-size:28px; 
      margin-bottom: 30px;
    }
    .gulf-drink-description{
      font-size:14px
    }
    .gulf-drink-content{
      right:2%;
      width:48%;
      top:52%;
    }
    .gulf-drink-link{
     font-size: 18px;
    }

    .animation-container{
      gap:80px;
    }
    .animation-container .text-box .text h1{
      font-size: 80px;
    }

    .animation-container .text-box .text img {
      height:150px
    }
    
}
@media screen and (min-width:800px) and (max-width:1130px){
  .olive-oil-box .down-arrow{ 
    display: none;
  }
  .olive-oil-content{
    top: 50%;
    /* transform: none; */
    width: 510px !important;
  }

  .olive-oil-content .olive-oil-image{
    height: 500px;
    width: auto;
  }
  .down-arrow.first{
    display: none !important;
  }
  .down-arrow.last{
    display: none !important;
  }

}

@media screen and (min-width:1201px) and (max-width:1300px){
  .down-arrow{
    height:20px !important
  }
}


@media screen and (min-width:1201px) and (max-width:1500px){
    .product-title, .gulf-drink-title
    .product-title, .gulf-drink-title,.juice-title,.line4 {
        font-size: 55px;
        margin-bottom:0px
    }
    .gulf-drink-title{
      /* line-height: 74px; */
    }
    .product-subtitle,.juice-subtitle,.line5{
        font-size:30px;
        margin-bottom:10px;
    }
    .line6{
      font-size:16px;
    }
    .juices_desc ul{
      font-size:16px;
    }
    .product-content{
      width:50%;
      left:20px;
    }
    .product-description{
      font-size: 16px;
      margin-bottom: -6px;
    }

    .product-benefits{
      font-size: 16px;
    }

    .product-benefits ul{
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .olive-oil-content{
      width:47%;
      right:20px;
    }
    .olive-oil-box{
      display:flex;
      justify-content:flex-start;
    }
    .olive-oil-subtitle{
      font-size:25px;
      margin-bottom:10px;
    }

    .olive-oil-image{
      height:auto !important;
      width:100% !important;
    }
    .gulf-drink-subtitle{
      font-size: 28px;
      margin-bottom: 79px;
    }
    .gulf-drink-description{
      font-size:16px
    }
    .gulf-drink-content{
      right:2%;
      width:48%;
      top:48%;
    }
    .gulf-drink-link{
     font-size: 18px;
    }
    .gulf-drink-links h3{
      font-size: 24px;
    }
    .animation-container{
      gap:30px;
    }
    .animation-container .text-box .text h1{
      font-size: 75px;
    }

    .animation-container .text-box .text img {
      height:120px
    }

  }

/* @media screen and (min-width:1501px) and (max-width:1650px){
  .product-title, .gulf-drink-title
  .product-title, .gulf-drink-title,.juice-title,.line4 {
      font-size: 5px;
  }
} */

  

  @media screen and (max-width:1020px){
    .olive-oil-content img{
      /* height:500px !important; */
      /* width:auto !important; */
    }
  }

@media screen and (max-height:560px){
  .banner img{
    width:100%;
    height:auto !important;
  }
}

@media screen and (min-width:1700px){
  .gulf-drink-subtitle{
    margin-bottom: 100px !important;
  }
}
