/* General navbar styling */
.navbar {
    position: sticky;
    top: 0;
    z-index: 1000;
}

.nav-link.active {
    font-weight: bold;
    color: #D50552 !important
}

.header {
    display: flex;
    flex-direction: column;
    padding: 0px !important;
}



.container-fluid {
    padding-right: 70px !important;
    padding-left: 70px !important;

}




.navbar-brand img {
    height: 82.5px;
    /* width: 215.39px; */
}

/* Navbar links */
.navbar-nav .nav-link {
    color: white;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 10px !important;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    display: block;
    margin-top: 0px !important;
}

.navbar_div {
    padding-bottom: 15px;
    padding-top: 15px;
}

.bg-nav-dark{
    background: rgba(0, 0, 0, .8);
    transition: .5s;
}

.sticky-top {
    padding-top: 0px !important;
}

.navbar-nav .nav-item .nav-link:hover,
.navbar-nav .nav-item .nav-link:focus,
.navbar-nav .nav-item .nav-link:active {
    color: #D50552;
    /* Darker mauve text color on hover */
}

/* Navbar toggler */
.navbar-toggler {
    border-color: #000000;
}

.nav-link:focus, .nav-link:hover{
    color: #D50552 !important;
}


/* Dropdown menu */
.nav-item.dropdown:hover .dropdown-menu {
    display: block;
    background-color: #111;
    border-color: #000000;
}

/* Dropdown item styling */
.dropdown-menu .dropdown-item {
    color: white;
    transition: background-color 0.3s ease;
    /* Smooth transition for background color */
    display: flex;
    align-items: center;
    /* Center items vertically */
    padding: 0.5rem 0.75rem;
    /* Reduced padding for a compact look */
    font-size: 0.875rem;
    /* Slightly smaller font size */
}

/* Adjust hover effects for dropdown items */
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
    background-color: #444444;
    /* Background color on hover for all dropdown items */
    color: #D50552;
    /* Darker mauve text color on hover for dropdown items */
}

/* Language switcher */
.language-switcher {
    margin-left: 10px;
    /* Space between buttons and language switcher */
}

.header-language {
    font-size: 16px;
    margin-right: 20px;
    padding-left: 10px;
    position: relative;
    cursor: pointer;
}

.translate_btn {
    position: relative;
    width: 130px;
    height: 40px;
    margin-right: 20px;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    background-color: #D50552;
    border: 1px solid white;
    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
    padding: 0px !important;
}

.about-us-sub-menu-container {
    background:  url("../../assets/images/About US.png");
    background-size: 100% 100%;
    /* Ensures the image covers the entire container */
    background-position: center;
    background-repeat: no-repeat;
    max-height: 100vh !important;
    min-height: 600px;
    position: relative;
    /* Ensures absolute children position relative to this */
    z-index: 1;
}


/* .global-presence-sub-menu-container {
    background: url("../../assets/images/Global Presence.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    max-height: 100vh !important;
    min-height: 600px !important;
    position: relative;
    z-index: 1;
} */

.our-products-sub-menu-container {
    background: url("../../assets/images/Our Products.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    max-height: 100vh !important;
    min-height: 600px !important;
    position: relative;
    /* Ensures absolute children position relative to this */
    z-index: 1;
}

.home-sub-menu-container {
    background: none;
}

.sub-menu-container {
    width: 100vw;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    z-index: 90000;
    position: absolute;
    bottom: 225px;
}


.sub-menu-title {
    font-size: 60px;
    font-weight: 600;
}
.gold-btn {
    background: linear-gradient(180deg, #F8AF00 0%, #D39416 100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

/* Custom Dropdown Styling */
.custom-dropdown-button {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
}

.custom-dropdown-menu {
    position: absolute;
    background: linear-gradient(to bottom, #250013, #3A0018);
    border-radius: 20px;
    padding: 10px 0;
    width: 160px;
    text-align: center;
    top: 100%;
    left: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.custom-dropdown-menu li {
    list-style: none;
    padding: 10px 0;
    border-bottom: 1px solid #D5145A80;;
}

.custom-dropdown-menu li:last-child {
    border-bottom: none;
}

.custom-dropdown-menu a {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.custom-dropdown-menu a:hover {
    color: #D50552;
    background: none;
}


/* Styles for responsive design if needed */
@media (max-width: 991.98px) {
    .navbar-nav .nav-item .btn {
        margin-left: 0;
        margin-top: 5px;
        /* Adjust spacing on small screens */
    }

    .navbar-nav .nav-link {
        border-bottom: 2px solid #D50552 !important;
        width: 100%;
        text-align: left;
    }

    .navbar-nav .language-switcher {
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .sub-menu-title {
        font-size: 40px;
        /* font-weight: 400; */
    }

    .navbar-brand img {
        height: 65px;
        margin-top: -11px;
        /* width: 147px; */
        /* margin-bottom: 30px; */
               
    }

    .about-us-sub-menu-container {
        background:  url("../../assets/images/responsive_banners/About US.png");
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        height: 400px !important;
        position: relative;
        z-index: 1;
    }


    /* .global-presence-sub-menu-container {
        background: url("../../assets/images/responsive_banners/Global Presence.png");
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        height: 400px !important;
        position: relative;
        z-index: 1;
    } */

    .our-products-sub-menu-container {
        background: url("../../assets/images/responsive_banners/Our Products.png");
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        height: 400px !important;
        position: relative;
        z-index: 1;
    }
 

    .navbar_div:has(.navbar-collapse.show) {
        background: linear-gradient(to bottom, #290011, #3D001A); /* New color */
    }
    
    .sub-menu-container {
        justify-content: end;
        bottom: 120px;
    }
    .container-fluid{
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
    .translate_btn{
        width: 100%;
    }
    .toggle-div{
        width: 100%;
        display: flex;
    }
    .toggle-div-img{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .navbar-toggler{
        width: 10%;
        border: none !important;
        cursor: pointer;
    }
    button:focus:not(:focus-visible){
        border: none !important;
        box-shadow:none !important
    }
}