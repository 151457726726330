

.aboutUs-container {
    /* padding-left: 100px; */
    /* padding-right: 100px; */
    padding-top: 100px;
    padding-bottom: 100px;

    position: relative;
    /* Needed for the pseudo-element */
    overflow: hidden;
}
.right-shadow{
    position:relative;
}
.right-shadow::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    /* width: 100%; */
    width: 450px;
    height: 100%;
    background-image: url("../../assets/images/red-shadow.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* Flip only the background */
    z-index: -1;
    /* Sends it behind the content */
}

.aboutUs-container-divs {
    display: flex;
    flex-direction: column;
    padding-top: 150px;
    position: relative;
}

.aboutUs-divs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.aboutUs-div {
    border-radius: 10px;
    background-color: #fff;
    color: #000;
    padding: 20px
}

/* Parent Container */
.vision-container {
    display: flex;
    padding: 20px;
    position: relative;
}

.vision-container-fullWidth .vision-content {
    max-width: fit-content !important;
}

.vision-container:nth-child(3){
    margin-top: 100px;
}

/* Content Wrapper */
.vision-content {
    align-items: center;
    background: white;
    padding: 40px;
    border-radius: 20px;
    border:2px solid #D50552;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    /* max-width: 500px; */
    background-image: url("../../assets/images/half-crown.png");
    text-align: center;
    background-size: 200px;
    background-repeat: no-repeat;
    background-position: right bottom;
    text-align: center;
    position: relative;
}

.vision-container::before{
    content: " ";
    background-image: url("../../assets/images/white-shadow.png");
    position: absolute;
    top: -155px;
    right: -185px;
    width: 100%;
    height: 490px;
    background-position: top right;
    background-repeat: no-repeat;
}

/* Circular Image */
.vision-image {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: -151px;
}

.vision-image-box{
    display: flex;
    gap: 30px;
    margin-bottom: 20px;
}

.vision-image-box h2 {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #000;
    white-space:nowrap;
}

.vision-image-box h2 span {
    color: #D50552;
    /* Dark pink/magenta color */
}

.aboutUs-divs2 {
    padding-top: 100px;
}

.vision-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.vision-image {
    border: 3px dashed #D50552; /* Apply border to the container */
}



/* Text Styling */
.vision-text {
    width: 100%;
}

.vision-text h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #000
}

.vision-text h2 span {
    color: #D50552;
    /* Dark pink/magenta color */
}

.vision-text p {
    font-size: 17px;
    color: #000;
    text-align: left;
    font-weight: 400;
}


/* Parent Container - Full-Screen */
.ceo-info-div {
    position: relative;
    /* height: 100vh; */
    /* Full screen height */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top:150px !important;
    margin-bottom:150px !important;
}

/* Background Image - Full Width */
.ceo-image-background {
    position: absolute;
    width: 100%;
    height: 100%;
}

.ceo-image-background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
 
    /* Ensures full width & height */
}

/* .ceo-info-div{
    margin-top: 250px;
    margin-bottom: 150px;
} */

/* CEO Foreground Image */
.ceo-image {
    position: relative;
    /* bottom: 0; */
    /* width: 35vw; */
    /* Adjust size based on screen width */
    max-width: 400px;
    margin-right: -350px;
    /* margin-top: 250px; */
    /* Prevents excessive size */
}

.ceo-image .ceo {
    width: 100%;
    height: auto;
    object-fit: contain;
}

/* Text Box */
.ceo-text-container {
    position: absolute;
    left: -400px;
    top: 50%;
    transform: translateY(-50%);
    width: 500px;
    padding: 50px;
    background: linear-gradient(to bottom, rgba(200, 10, 90, 0.9), rgba(150, 0, 50, 0.9));
    color: white;
    border-radius: 15px;

}

.ceo-text-container .title{
    display: flex;
    align-items: center;
    gap:20px;
    position:a

}

.ceo-text-container .title img{
    width: 135px;
    height: 60px;
    margin-left: -100px;
}
.ceo-text-container .title p{
    font-size: 40px;
    line-height: 43px;
}
.white-crown-box{
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 30px;
}

.ceo-text-container h2 {
    font-size: 24px;
    font-weight: bold;
}

.ceo-text-container span {
    color: #f8c400;
    /* Highlighted text */
}

.ceo-text-container p{
    font-size: 22px;
    line-height: 29px;
}

.quote-mark {
    font-size: 40px;
    font-weight: bold;
    color: white;
}

.black-color {
    color: #000;
}

.right-quote {
    display: flex;
    justify-content: flex-end;
    /* Moves text to the right */
    width: 100%;
}

.right-quote img{
    height:50px;
}

.left-quote img{
    height:70px;
}



.crown-div {
    background-image: url("../../assets/images/crown_image.jpg");
    align-items: center;
    padding: 40px;
    border-radius: 33px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    background-size: cover;
    position:relative
}

.crown-div .right-image{
    position:absolute;
    height: 217px;
    right: -81px;
    top: -71px;
    z-index: -1;
}
.crown-div .left-image{
    position:absolute;
    height: 217px;
    left: -81px;
    bottom: -71px;
    z-index: -1;
}

.descriptions-divs {
    display: flex;
    flex-direction: column;
    padding: 20px;
    text-align: center;
    gap: 20px;
    margin-top: 150px !important;
}

.description-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flex-center {
    justify-content: center !important;
}

.description-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 450px;
    gap: 20px;
    margin-bottom: 50px;
}

.description-container img {
    height: 100px
}

.description-container h4{
    color: #FFF;
    text-align: center;
    /* font-family: Poppins; */
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 100% */
    margin-bottom: 0px !important;
}

.description-container span{
    color: #FFF;
    text-align: center;
    /* font-family: Poppins; */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.desc-text {
    align-items: center;
}
.bottom-section{
    position: relative;
}
.ceo-section{
    position: relative;
}
.bottom-section .right-shadow{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 900px;
}
.ceo-section .left-shadow{
    position: absolute;
    top: 150px;
    left: 0;
    height: 600px;
    transform: rotate(180deg);
}



@media (max-width: 991.98px) {
    .aboutUs-divs {
        flex-direction: column;
    }

    .aboutUs-divs2 {
        padding-top: 0px !important;
    }

    .vision-container {
        display: block;
        padding-top: 50px;
        margin-bottom:20px;
    }

    .aboutUs-container {
        padding-left: 0px;
        padding-right: 0px;
    }

    .vision-container .vision-content {
        max-width: fit-content !important;
        padding:20px;
    }

    .vision-container:nth-child(3){
        margin-top:0px
    }

    .vision-image-box{
        gap:8px;
        
    }

    .vision-image-box h2{
        font-size:28px;
        white-space:wrap;
    }

    .vision-image {
        width: 150px;
        height: 150px;
        margin-top: -90px;
    }
    
    .ceo-info-div {
        flex-direction: column;
        height: auto;
        padding: 5%;
        margin-top:50px;
    }

    .ceo-text-container {
        position: static;
        width: 90%;
        text-align: center;
        margin-bottom: 20px;
    }

    .ceo-image {
        position: static;
        width: 60vw;
        max-width: 250px;
    }
    .ceo-text-container {
        width:100%;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        padding: 20px;
        padding-top: 80px;
        padding-bottom: 50px;
        margin-top: -41px;
        z-index: -1;
    }

    .ceo-image {
        width: 90vw;
        max-width: 100%;
    }
    .description-div {
        flex-direction:column !important;
    }
    .description-container{
        width: 100%;
    }

    .ceo-text-container .title p {
        font-size: 26px;
        line-height: 30px;
        text-align: left;
        margin-bottom:0px !important;
    }

    .ceo-text-container .title img{
        width:90px;
        margin-left: -47px;
        margin-right: 0px;
    }

    .right-quote img {
        height: 27px;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .left-quote img {
        margin-top: 10px;
        height: 27px;
    }

    .vision-container::before {
        top: -127px;
        right: -168px;
        width: 474px;
        height: 490px;
    }

    .ceo-info-div{
        padding:0px !important;
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }
    .ceo-image{
        margin-right:0px !important
    }

    .ceo-text-container p {
        font-size: 17px;
        line-height: 25px;
    }
    .crown-div img {
        height: 33px;
    }

    .crown-div h2 {
        font-size: 19px;
        margin-bottom: 0px;
    }
    .descriptions-divs{
        margin-top:55px !important;
    }
    .description-container h4{
        font-size:20px;
    }

    .description-container span{
        font-size:16px;
    }
    .aboutUs-container-divs{
        padding-top:0px;
    }
}

.hide-nav{
    /* display:none !important;  */
    margin-top: -100%;
    transition: .3s;
}

.show-nav{
    margin-top:0;
    transition: .3s;
}
