.contactUs-container {
  display: flex;
  padding-top: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
  position: relative;
  /* Needed for the pseudo-element */
  overflow: hidden;
  margin-top: -57px;
}

.contactUs_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  margin-bottom: 30px;
}
.react-tel-input {
  position: relative;
}

.react-tel-input .country-list {
  position: absolute !important;
  left: 0 !important;
  max-width: 90vw; /* Limits the width on mobile */
  overflow-x: hidden;
}

.find_us {
  color: #d50552;
  font-size: 50px;
  line-height: 57px;
  font-weight: 700;
}

.contact_icons {
  color: #d50552;
}

.find_us_div {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative; /* For positioning the pseudo-element */
  display: flex;
  flex-direction: row;
}
.span_title {
  width: 20%;
}
.join_div {
  font-size: 20px;
  margin-bottom: 20px;
}
.social_media_icons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.find_us_div::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #ffe3ed 0%, #d50552 100%);
}

/* Contact Form Container */
.contactUs-container .contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Form Styling */
.contactUs-container .contact-form {
  width: 100%;
}

/* Form Row */
.contactUs-container .form-row {
  display: flex;
  gap: 15px;
}

/* Form Group */
.contactUs-container .form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.contactUs-container .form-group label {
  color: #fff;
  font-size: 20px;
  line-height: 41.82px;
  font-weight: 200;
  margin-bottom: 5px;
}

/* Input Fields */
.contactUs-container .form-group input,
.contactUs-container .form-group textarea {
  width: 100%;
  padding: 12px;
  /* Default border */
  border: 1px solid #aaa;
  border-top: 1px solid #d50552;
  border-radius: 5px;
  background: black;
  color: #fff;
  font-size: 16px;
}

.contactUs-container .form-group input::placeholder,
.contactUs-container .form-group textarea::placeholder {
  color: #aaa;
}

/* Phone Input Group */
.contactUs-container .phone-group {
  position: relative;
}

.contactUs-container .phone-input {
  display: flex;
  align-items: center;
  background: black;
  border: 1px solid #d50552;
  border-radius: 5px;
}

.contactUs-container .phone-icon {
  color: #d50552;
  margin: 10px;
}

.contactUs-container .phone-input input {
  flex: 1;
  border: none;
  background: transparent;
  color: #fff;
  padding: 12px;
}

/* Textarea */
.contactUs-container .form-group textarea {
  height: 160px;
  resize: none;
}

/* Submit Button */
.contactUs-container .submit-btn {
  width: 170px;
  padding: 12px;
  background: #d50552;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  float: right;
}

.contactUs-container .submit-btn:hover {
  background: #a1043a;
}

.contactUs-container .react-tel-input .flag-dropdown,
.contactUs-container .react-tel-input .flag-dropdown.open {
  background: none !important;
  border: none !important;
}

.contactUs-container .react-tel-input .country-list {
  background-color: #000 !important;
}

.contactUs-container .react-tel-input .country-list .country:hover {
  background: linear-gradient(to bottom, #290011, #3d001a) !important;
}

.contactUs-container .react-tel-input .flag-dropdown.open .selected-flag {
  background-color: none !important;
}

.contactUs-container .react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: none !important;
}

.contactUs-container .react-tel-input .country-list .country.highlight {
  background: linear-gradient(to bottom, #290011, #3d001a) !important;
}

.contactUs-container .form-control:focus {
  box-shadow: none !important;
}

/* PHONE INPUT STYLING */
.phone-container {
  display: flex;
  align-items: center;
  border: 1px solid #aaa;
  border-top: 1px solid #d50552;
  border-radius: 8px;
  padding: 5px;
  position: relative;
  width: 100%;
}

/* Style the react-phone-input-2 container */
.react-tel-input {
  width: 100%;
}

/* Input inside phone input */
.phone-input-field {
  width: 100% !important;
  background: transparent !important;
  border: none !important;
  color: #fff !important;
  font-size: 16px !important;
  padding-left: 60px !important;
  /* Ensures space for flag */
}

/* Country dropdown button */
.flag-dropdown {
  position: absolute;
  left: 10px;
  background: transparent !important;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

/* Country flag icon */
.flag-dropdown .selected-flag {
  padding: 5px;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

/* Ensuring country code is positioned in front */
.phone-input-field::before {
  content: "+1";
  /* Default country code */
  position: absolute;
  left: 40px;
  /* Moves code in front of flag */
  color: #fff;
  font-size: 16px;
  z-index: 2;
}

/* Dropdown styling */
.phone-dropdown {
  background: #3d001a !important;
  border: 1px solid #d50552 !important;
}

.phone-dropdown .country-list {
  background: #3d001a !important;
  border: 1px solid #d50552 !important;
}

.phone-dropdown .country:hover {
  background: #d50552 !important;
  color: #fff !important;
}

@media (max-width: 991.98px) {
  .contactUs-container {
    flex-direction: column;
  }
  .contactUs-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .contactUs-container .form-row {
    flex-direction: column;
  }
  .contactUs-container .submit-btn {
    width: 40%;
  }
  .find_us_div {
    flex-direction: column;
  }
  .span_title {
    width: 100%;
    font-size: 25px;
    line-height: 30px;
  }
  .social_media_icons {
    width: 30%;
  }
}

.contact-bg-logo {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* opacity: 0.1; */
}

.contact-bg-fav {
  margin-top: 170px;
}

.contact-us-wrapper {
  overflow-x: hidden;
}

.contact-left-center-shadow {
  position: absolute;
  top: 40%;
  right: -360px;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 850px;
  background: rgba(97, 20, 44);
  z-index: -1;
  border-radius: 922.201px;
  border: 1px solid rgba(97, 20, 44);
  filter: blur(100px);
}

@media (max-width: 768px) {
  .contact-left-center-shadow {
    position: absolute;
    top: 20%;
    right: -200px;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 400px;
    background: rgba(97, 20, 44);
    z-index: -1;
    border-radius: 922.201px;
    border: 1px solid rgba(97, 20, 44);
    filter: blur(47px);
  }

  .contact-bg-fav {
    margin-top: 390px;
  }
}

.input-box {
  position: relative;
  padding: 1px;
}

.input-box::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(to bottom, #d50552, #ffe3ed);
  z-index: -1;
  border-radius: 5px;
}

input {
  border: 0px solid transparent !important;
  box-shadow: none;
  outline: 0;
}

.react-tel-input .form-control {
  height: 50px !important;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}