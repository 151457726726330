.app-container-coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 2100px;
  background-image: url('../../assets/images/turkish.jpg');
  background-size: 100% 100%;
  background-repeat:no-repeat; */
  /* padding-top: 250px; */
  position: relative;
  overflow: hidden;
}

.coming-soon {
  text-align: center;
}
.app-container-coming-soon  .bg-image{
  height: auto !important;
  width: 100%;
}

.hero-content {
  font-size: 5rem;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-content h2,
.hero-content h3 {
  color: white;
  font-size: 3.7rem;
}

.hero-content p {
  color: white;
  font-size: 3.5rem;
  margin: -17px !important;
}

/* Apply color to the 'Quality' text */
.hero-content .quality-text {
  color: #d50552; /* Color for 'Quality' text */
}

/* Transparent background for Product section */
.product-section {
  background-color: transparent; /* Makes the background transparent */
}

/* Increase the size of the 'Bringing Quality Taste, and Wellness in Every Drop' text */
.hero-content h1 {
  font-size: 2.8rem; /* Increased size */
  font-weight: bold;
  line-height: 1.4;
}

/* Call to Action Buttons */

/* Individual CTA Button Styles */
/* Base CTA Button Styles */
.cta-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: transparent; /* Transparent button background */
  text-decoration: none;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

/* For 'Our Products' button */
.our-products {
  border: 2px solid #d50552; /* White border for 'Our Products' */
  background-color: #d50552; /* Button color when hovered */
  color: white;
  border-radius: 4px;
  font-weight: bold; /* Make text bold */
}

/* For 'Get Started' button */
.get-started {
  border: 2px solid #d50552; /* White border for 'Our Products' */
  font-weight: bold;
  border-radius: 0; /* No border radius */
}

/* CTA Button Hover Effect */
.cta-button:hover {
  background-color: #d50552; /* Button color when hovered */
  font-weight: bold;
  color: white; /* Text color when hovered */
}

/* Specific hover effect for 'Our Products' (border color remains white) */
.our-products:hover {
  border-color: white; /* Maintain white border on hover */
}

.get-started:hover {
  background-color: transparent; /* Ensure no background color on hover */
  color: #d50552;
  border: none; /* Ensure no border appears on hover */
}
.cta-button i {
  margin-right: 0.5rem; /* Add space between icon and text */
  font-size: 1.2rem; /* Adjust icon size */
}

.slider-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.slide {
  min-width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
}

.crown-image {
  display: block;
  margin: 0 auto 1rem;
  /* margin-top: 10%; */
  width: 50%;
  height: auto;
  position: absolute;
  bottom: 13%;
  left: 50%;
  transform: translateX(-50%);
}

.button-container {
  position: absolute;
  bottom: -33%;
  right: -55px;
  transform: translateX(-50%);
  display: flex;
  gap: 15px;
}

.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.nav-button {
  width: 50px;
  height: auto;
  border-radius: 5px;
}

.cta-buttons{
  display: flex;
  gap: 10px;
  justify-content: center;
}


.subtitle-box{
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 50px;
  gap: 15px;
}


@media screen and (max-width: 850px) {
  .hero-content{
      justify-content: flex-start;
      align-items: center;
      padding-top: 185px;
  }
}
@media screen and (max-width: 853px) {
  .hero-content{
      top: -200px;
  }
  .crown-image {
      bottom: 23% !important;
  }
}
@media screen and (max-width: 412px) {
  .hero-content{
    top: 0%;
  }
}
@media screen and (max-width: 1024px) {
    .crown-image {
        bottom: 14% !important;
    }
}
@media screen and (min-width: 769px) and (max-width: 1000px){
    .crown-image {
        bottom: 25% !important;
    }
}
@media screen and (min-width: 650px) and (max-width: 700px){
    .hero-content{
      padding-top: 100px;
    }
}
@media screen and (max-width: 768px) {
  .app-container-coming-soon {
    overflow: hidden;
  }
  .crown-image{
    bottom: 28% !important;
  }
}

@media screen and (max-width: 650px) {
  .app-container-coming-soon .bg-image{
    width: auto;
    height: 170vh !important;
  }
  .hero-content{
      justify-content: flex-start;
      align-items: center;
      padding: 20px;
      padding-top: 185px;
  }
  .hero-content h2, .hero-content h3{
    font-size: 2.5rem;
    margin-bottom: 15px;
  }

  .hero-content p{
      font-size: 2.2rem;
      margin: 0px !important;
      /* margin-bottom: -9px !important; */
  }
  .cta-buttons{
    margin-top: 20px;
  }
  .cta-button{
    font-size: 1rem;
    font-weight: 600;
    border-radius: 7px;
  }

  .crown-image{
    width: 85%;
    bottom: 9%;
  }

  .subtitle-box p{
    line-height: 35px;
  }


  .subtitle-box{
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 5px;
  }
}
@media screen and (max-width: 380px) {
  .crown-image {
    width: 200px;
    bottom: 35% !important;
  }
}


/* Certificate Section */
.certificate {
  margin: auto;
  width: 100%;
  position: absolute;
  top: 87%;
}

.row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.col-md-6 {
  display: flex;
  justify-content: center; /* Centers the card-section */
}

.card-section {
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  width: 100%;
  max-width: 100%; /* Adjust max-width as needed */
  height: 180px;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  position: relative;
}

.card-content {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.left-image {
  width: 130px;
  height: auto;
}

.card-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-text p {
  font-family: 'Poppins';
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
}

.card-text .download-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  margin-top: 10px;
  cursor: pointer;
  align-self: flex-end;
}

@media (max-width: 1200px) {
  .card-text p {
    font-size: 14px;
  }
}
@media screen and (max-width: 380px) {
  .certificate{
    top: 68% !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1000px) {
  .certificate{
    top: 80%;
  }
}
@media screen and (min-width: 650px) and (max-width: 700px){
    .certificate{
      top: 68%;
    }
}
@media (max-width: 768px) {
  .certificate{
    margin: auto;
    width: 100%;
    position: absolute;
    top: 72%;
  }
  .row {
    flex-direction: column;
    align-items: center;
  }

  .col-md-6 {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .card-section {
    max-width: 100%;
    height: auto;
    padding: 1rem;
    margin-bottom: 10px;
  }

  .left-image {
    width: 100px;
  }

  .card-text p {
    font-size: 14px;
  }

  .card-text .download-button {
    font-size: 12px;
  }
}
