

.global-presence-container h1 {
  color: #D50552;
  /* Dark gray text color */
  margin-bottom: 20px;
}



.international-network-heading {
  color: #D50552;
  /* Set the text color to #D50552 */
  margin-bottom: 20px;
  /* Add spacing below the heading */
}

.global-presence-containerh2 {

  color:#F8AF00 !important;
}
.global-presence-container p {
  color: #555;
  /* Medium gray text color */
  font-size: 1.1rem;
  max-width: 800px;
  margin: 0 auto 20px;
}

.global-presence-container .map-container .map-img {
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 8px;
  /* Optional: Add rounded corners to the map */
}

.global-presence-container .map-section {
  display: flex;
  justify-content: center;
  /* Center the map horizontally */
  align-items: center;
  /* Center the map vertically */
  margin-bottom: 20px;
  /* Space between map and text */
  padding-top: 100px;
  padding-bottom: 100px;
}

.global-presence-container .map-section .map-img {
  width: 85%;
  /* Increase the size of the map */
  /* max-width: 1000px; */
  /* Increase the maximum width */
  height: auto;
  border-radius: 8px;
  /* Optional: Add rounded corners to the map */
}

.global-presence-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 50px;
}

@media (max-width: 768px) {
  .global-presence-container {
    padding-top: 30px;
  }
}

.map-section {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}



/* Marker positioning */
.map-marker {
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

/* Optional: Add hover effect for markers */
.map-marker:hover {
  transform: translate(-50%, -50%) scale(1.2);
  transition: transform 0.2s ease-in-out;
}


.left-center-shadow {
  position: absolute;
  top: 50%;
  right: -360px;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 850px;
  background: rgba(97, 20, 44);
  z-index: -1;
  border-radius: 922.201px;
  border: 1px solid rgba(97, 20, 44);
  filter: blur(100px);
}

@media (max-width: 768px) {
  .left-center-shadow {
    position: absolute;
    top: 50%;
    right: -200px;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    background: rgba(97, 20, 44);
    z-index: -1;
    border-radius: 922.201px;
    border: 1px solid rgba(97, 20, 44);
    filter: blur(47px);
  }
}