/* General Styles */
.our-position-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-top: 0px;
}

.background-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    height: auto;
    width: 800px;
}

.right-background-logo{
    position: absolute;
    top: -900px;
    right: -1%;
    opacity: 1;
    height: auto;
    width: auto;
}

.our-position-content {
    width: 90%;
    padding: 20px;
    border-radius: 10px;
    color: white;
    margin-top: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
}

.our-position-title {
    display: flex;
    flex-direction: column;
    line-height: 1.2;
}

.pink-title {
    color: #D50552;
    text-align: center;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.yellow-title {
    color: #F8AF00;
    text-align: center;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.our-position-subtitle {
    color: #FFF;
    text-align: center;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 35px;
}

@media (max-width: 768px) {

    .our-position-content {
        width: 90%;
        text-align: center;
    }

    .background-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        height: auto;
        width: 400px;
    }

    .right-background-logo {
        position: absolute;
        top: -400px;
        right: -80px;
        opacity: 1;
        height: 1200px;
    }

    .pink-title {
        color: #D50552;
        text-align: center;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }

    .yellow-title {
        color: #F8AF00;
        text-align: center;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .our-position-subtitle {
        color: #FFF;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin-top: 35px;
    }
}