/* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html{
  overflow-x:hidden;
}

body{
  margin: 0;
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #000000;
  color: #f4f4f4;
}

a {
  color: #f4f4f4;
  text-decoration: none;
}

.banner{
  /* background:  url("./assets/images/responsive_banners/Contact US.png"); */
  background-size: 100% 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  /* min-height: 100; */
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.banner img{
  /* width: 100%; */
  height:100vh;
}
.banner .text-box{
  position: absolute;
  left: 50%;
  transform:translateX(-50%);
  bottom: 20%;
  text-align: center;
  width: max-content;
}
.banner .title{
  font-size: 60px;
  font-weight: 600;
}
.banner .subtitle{
  font-size: 20px;
  font-weight: 400;
}

@media (max-width: 860px) {
  .banner {
    overflow-x: hidden;
    display: flex;
    justify-content: center;
  }
  .banner img{
    height: 400px;
    width: auto;
  }
  .banner .title{
    font-size:28px;
    margin-bottom: 0px;
  }
  .banner .subtitle{
    font-size:16px;
    margin-bottom: 0px;
  }
}

@media (max-width: 991.98px) {
  

  .banner .text-box{
    /* bottom: 80px; */
  }
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
